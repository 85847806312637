import { FC } from 'react';
import { useSession } from 'next-auth/react';
import { useApolloClient } from '@apollo/client';
import { Button, Popconfirm, message, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { ArchiveButtonProps } from './ArchiveButton.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';
import { GET_FTRAN_LIST } from '~/apollo/queries/ftrans';
import { userHaveAdmins } from '~/utils';

const ArchiveButton: FC<ArchiveButtonProps> = ({ order, router }) => {
  const client = useApolloClient();

  const { data: session } = useSession();
  const userHaveAdmin = userHaveAdmins(session?.user.user_id);

  const [updateOrder, { loading }] = useUpdateOrderByPkMutation({
    onCompleted: () => {
      router.push('/orders');
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onConfirm = async () => {
    // const { status, order_quarter } = order;

    // if (status?.id === 1) {
    //   const { data } = await client.query({
    //     query: GET_FTRAN_LIST,
    //     variables: {
    //       where: {
    //         order_id: {
    //           _eq: order.id
    //         }
    //       }
    //     }
    //   });

    //   if (data.t_ftran.length > 0) {
    //     return message.error('Данную сделку нельзя отправить в архив, тк к ней привязаны Расходы!');
    //   }
    // }

    // if (status?.id === 2 || status?.id === 3) {
    //   message.error('Данную сделку с указанным статусом нельзя отправить в архив!');

    //   return;
    // }

    // if (!order_quarter && status?.id === 4) {
    //   message.error('Данную сделку нельзя перенести в Архив, тк не проставлен Квартал.');

    //   return;
    // }

    // if (status?.id === 4) {
    //   const { bill_date, bill_number, invoice_number, invoice_date } = order;

    //   if (!bill_date || !bill_number || !invoice_number || !invoice_date) {
    //     message.error('Не заполнены все поля Счета или УПД!');

    //     return;
    //   }
    // }

    // в архив заказ могут отправить только администраторы
    if (!userHaveAdmin) {
      if (order.order_expenses_fakt > 0) {
        return message.error('В заказе есть расходы. В архив заказ могут отправить только администраторы!');
      }
    }

    updateOrder({
      variables: {
        orderId: order.id,
        set: {
          archived: true
        }
      }
    });
  };

  return (
    <Popconfirm
      title="Отправить в архив?"
      okText="В архив"
      cancelText="Передумал"
      onConfirm={onConfirm}
      disabled={loading}
      placement="bottomRight"
    >
      <Tooltip placement="bottomRight" title="В архив">
        <Button icon={<InboxOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default ArchiveButton;
